<template>
  <div class="relative h-screen w-full flex items-center">
    <div class="absolute top-50px left-100px">
      <a
        class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
        href="/"
      >
        <blacklogo class="" />
      </a>
    </div>
    <div class="container flex flex-col">
      <div class="lg:w-full">
       <h1 class="font-medium text-2xl text-center">Registration</h1>
      </div>
        <div class="bg-white lg:w-full md:6/12 w-full my-auto mr-auto">
          <form @submit.prevent="register" class="mt-6">

            <div class="flex w-full">
              <div class="w-half mr-50px">
                <div class="my-5 text-sm">
                  <label for="email" class="block text-black">Email</label>
                  <input
                    type="text"
                    v-model="form.email"
                    @blur="$v.form.email.$touch"
                    id="email"
                    class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full"
                    placeholder="Email"
                    :class="{ 'border-1 border-red-500': $v.form.email.$error }"
                  />
                  <div
                    v-if="!$v.form.email.required && $v.form.email.$error"
                    class="text-red-500"
                  >
                    The email field is required
                  </div>
                  <div
                    v-else-if="!$v.form.email.email && $v.form.email.$error"
                    class="text-red-500"
                  >
                    The email field is invalid
                  </div>
                </div>
              </div>
              <div class="lg:w-half">
                <div class="my-5 text-sm">
                  <label for="role" class="block text-black"
                    >Role</label
                  >
                  <input
                    type="text"
                    v-model="form.role"
                    id="role"
                    class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full"
                    placeholder="Role"
                    :class="{
                      'border-1 border-red-500': $v.form.role.$error,
                    }"
                  />
                  <div v-if="$v.form.role.$error" class="text-red-500">
                    Role must be longer than 3 letters long.
                  </div>
                </div>
              </div>
            </div>

            <div class="flex w-full">
              <div class="w-half mr-50px">
                <div class="my-5 text-sm">
                  <label for="password" class="block text-black">Password</label>
                  <input
                    type="password"
                    v-model="form.password"
                    @blur="$v.form.password.$touch"
                    id="password"
                    class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full border-gray"
                    placeholder="Password"
                    :class="{ 'border-1 border-red-500': $v.form.password.$error }"
                  />
                  <div
                    v-if="!$v.form.password.required && $v.form.password.$error"
                    class="text-red-500"
                  >
                    The password field is required
                  </div>
                  <div v-else-if="!$v.form.password.minLength" class="text-red-500">
                    The password field should have 3 characters
                  </div>
                </div>
              </div>
              <div class="lg:w-half">
                <div class="my-5 text-sm">
                  <label for="secondary-password" class="block text-black"
                    >Confirm Password</label
                  >
                  <input
                    type="password"
                    v-model="form.secondaryPassword"
                    @blur="$v.form.secondaryPassword.$touch"
                    id="secondary-password"
                    class="rounded-sm px-4 py-3 mt-3 bg-gray-100 w-full"
                    placeholder="Password"
                    :class="{
                      'border-1 border-red-500': $v.form.secondaryPassword.$error,
                    }"
                  />
                  <div v-if="$v.form.secondaryPassword.$error" class="text-red-500">
                    The confirm password should be the same as the password
                  </div>
                </div>
              </div>
            </div>
            <div v-if="errorMessage" class="absolute mt-100px text-red-500">
              {{ errorMessage }}
            </div>
            <button
              class="flex items-center justify-center relative text-white duration-300 rounded-full lg:w-3/12 mr-50px py-16px btn--arrow btn--arrow-white"
              :class="$v.form.$error ? 'bg-teal text-white-800 cursor-default' : 'bg-teal hover:bg-black'"
              :disabled="$v.form.$error"
            >
              <loading v-if="false" class="w-4 h-4 fill-current animate-spin duration-75" />
              <span class="">Register now</span>
            </button>
            <div class="pt-6 text-xs text-center font-light text-gray-400">
              Already have an account?
              <router-link
                to="/"
                class="text-black hover:text-gray-700 font-medium underline"
                >Log in</router-link
              >
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script>
import blacklogo from '@/assets/svg/blacklogo.svg';
import loading from '@/assets/svg/loading.svg';
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import firebase from "firebase";

export default {
  name: "RegistrationForm",

  components: {
    loading,
    blacklogo
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },

      role: {
        required,
        minLength: minLength(3),
      },

      password: {
        required,
        minLength: minLength(3),
      },

      secondaryPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },

  data() {
    return {
      form: {
        email: null,
        role: null,
        password: null,
        secondaryPassword: null,
      },
      errorMessage: "",
      isLoading: false
    };
  },

  methods: {
    register() {
      this.onTouchForm();
      if (!this.$v.form.$error) {
        this.isLoading = true;
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.form.email, this.form.password)
          .then(() => {
            this.$router.replace('/companyinfo')
          })
          .catch((error) => {
            this.isLoading = false;
            this.errorMessage = error.message;
          });
      }
    },

    onTouchForm() {
      this.$v.form.$touch();
    },
  },
};
</script>
