<template lang="html">
  <registration-form />
</template>

<script>
import RegistrationForm from "@/views/Registration/RegistrationForm";

export default {
  name: "Registration",

  components: {
    RegistrationForm,
  },
};
</script>
